import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/page-layout.js";
import { format } from "date-fns";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Grid = makeShortcode("Grid");
const SiteMetaData = makeShortcode("SiteMetaData");
const Fragment = makeShortcode("Fragment");
const Heading = makeShortcode("Heading");
const Text = makeShortcode("Text");
const Button = makeShortcode("Button");
const Link = makeShortcode("Link");
const Alert = makeShortcode("Alert");
const Box = makeShortcode("Box");
const SourceTags = makeShortcode("SourceTags");
const Flex = makeShortcode("Flex");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Grid sx={{
      mb: 6
    }} mdxType="Grid">
      <SiteMetaData mdxType="SiteMetaData">
  {siteMetadata => {
          const {
            name,
            description
          } = siteMetadata;
          return <Fragment mdxType="Fragment">
        <Heading variant="styles.h1" mdxType="Heading">{name}</Heading>
        <Text mdxType="Text">{description}</Text>
      </Fragment>;
        }}
      </SiteMetaData>
      <p>{`避免失联可以先收藏然后点 star ⭐`}</p>
      <Grid sx={{
        alignItems: 'center',
        gridTemplateColumns: 'auto 1fr'
      }} mdxType="Grid">
        <Button sx={{
          mb: 2
        }} as="a" variant="success" href="https://github.com/fexba/ba" target="_blank" mdxType="Button">
  🌏View theme on GitHub
        </Button>
        <Link sx={{
          whiteSpace: 'nowrap',
          color: '#fff',
          fontSize: '20px',
          textDecoration: 'none'
        }} href="https://github.com/fexba/ba" target="_blank" mdxType="Link"> 🌏 面霸</Link>
      </Grid>
      <Alert variant="success" mdxType="Alert">Alert... My website is faster than yours!</Alert>
    </Grid>
    <Grid sx={{
      gap: 0,
      mb: 6
    }} mdxType="Grid">
      <h2>{`About`}</h2>
      <p>{`本仓库为前端面试题库，包含不限于 Vue 面试题，React 面试题，JS 面试题，HTTP 面试题，工程化面试题，CSS 面试题，算法面试题，大厂面试题，高频面试题，如果收集的文章侵犯您的利益，请与我们联系！`}</p>
    </Grid>
    <Grid sx={{
      gridTemplateColumns: ['1fr', '1fr', '1fr 1fr']
    }} mdxType="Grid">
      <Box mdxType="Box">
        <h2>{`Contact`}</h2>
        <Heading variant="styles.h3" sx={{
          mb: 0
        }} mdxType="Heading">
  Email
        </Heading>
        <Link href="mailto:zoeblow@gmail.com" variant="styles.a" mdxType="Link">
  zoeblow@gmail.com
        </Link>
        <Heading variant="styles.h3" sx={{
          mb: 0
        }} mdxType="Heading">
  GitHub
        </Heading>
        <Link href="https://github.com/fexba/ba" variant="styles.a" target="_blank" mdxType="Link">
  https://github.com/fexba/ba
        </Link>
      </Box>
      <Box mdxType="Box">
        <h2>{`Top 5 tags`}</h2>
        <SourceTags mdxType="SourceTags">
  {source => <Flex sx={{
            flexDirection: 'column'
          }} mdxType="Flex">
      {source.splice(0, 5).sort((a, b) => b.percent - a.percent).map((tag, index) => {
              const {
                name,
                count,
                percent
              } = tag;
              return <Box key={index} sx={{
                color: 'muted',
                mb: 3,
                svg: {
                  fill: 'muted'
                }
              }} mdxType="Box">
              <Flex sx={{
                  lineHeight: 'normal'
                }} mdxType="Flex">
                <Text sx={{
                    color: 'secondary',
                    mr: 2,
                    mb: 0
                  }} mdxType="Text">{`${name}(${count})`}</Text>
                <Text sx={{
                    color: 'muted'
                  }} mdxType="Text">{percent}</Text>
                <Text sx={{
                    color: 'error'
                  }} mdxType="Text">%</Text>
              </Flex>
              <Flex mdxType="Flex">
                <Box mdxType="Box">{'['}</Box>
                <Box sx={{
                    flexBasis: '100%'
                  }} mdxType="Box">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 8" width="100%" height="8">
                    <rect width={`${percent * 3}%`} height={4} />
                  </svg>
                </Box>
                <Box mdxType="Box"> {']'}</Box>
              </Flex>
            </Box>;
            })}
    </Flex>}
        </SourceTags>
      </Box>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      